import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";
import { BaseChatInfo } from "@/includes/types/Chat/types";
import { BaseNetworkData } from "@/includes/types/networks";

export const enum TriggersMode {
  Parallel = 'Parallel',
  Linear = 'Linear'
}

export type GetChatTriggersRes<T extends boolean> = T extends true ? ActiveChatTriggers : RemovedChatTriggers

export type RemovedChatTriggers = {
  triggers: Array<RawTriggerData>
}

export type ActiveChatTriggers = {
  active: Array<RawTriggerData>
  disabled: Array<RawTriggerData>
  networks: Array<Pick<BaseNetworkData, 'id' | 'name'> & { triggers: Array<RawTriggerData> }>
}

export type EditChatTriggersReq = {
  chat_id: BaseChatInfo['chat_id']
  action: 'Set' | 'Delete' | 'Restore'
  triggers: Array<string>
}
