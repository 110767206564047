
































import { TriggersMode } from "@/includes/types/triggers";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    SwitchInput,
    Icon,
    HelpMessage
  }
})
export default class TriggersModeSwitch extends Mixins(ModelSetter) {

  get triggersMode(): boolean {
    return this.$store.state.chatState.chat.triggers_mode === TriggersMode.Parallel
  }

  set triggersMode(value: boolean) {
    const triggers_mode = value ? TriggersMode.Parallel : TriggersMode.Linear

    this.$store.dispatch('saveChatConfig', { triggers_mode })
  }
}
