var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"triggers-mode-switch"},[_c('help-message',{staticClass:"absolute",staticStyle:{"right":"-10px","top":"-10px","z-index":"10"},attrs:{"help-message":_vm.$t('trigger_mode_help_message')}}),_c('switch-input',{staticClass:"triggers-mode-switch__switch",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'triggersMode',
        'titleVisible': false,
        'customHelpMessage': _vm.$t('trigger_mode_help_message'),
        'disabled': !_vm.$store.getters.canChangeConfig
      }
    }}},[_c('icon',{attrs:{"slot":"checkedChildren","icon":"call_split"},slot:"checkedChildren"}),_c('icon',{attrs:{"slot":"unCheckedChildren","icon":"call_merge"},slot:"unCheckedChildren"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }