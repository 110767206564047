import i18n from "@/i18n/i18n";
import { ChatMetrics, TriggerStatRecord } from "@/includes/types/DashboardExtended/types";
import store from "@/store/store";

import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'

import { groupBy, sum, sumBy } from "lodash";
import moment from "moment";

let triggersState: Pick<ChatMetrics, 'triggers_stat' | 'period_start' | 'period_end'> | null = null

export function triggersStatistics(metrics?: ChatMetrics) {
  if (!store.state.triggersState.triggers) {
    store.dispatch('updateTriggers')
  }

  if (metrics) {
    if (triggersState === null) {
      triggersState = {
        triggers_stat: [],
        period_start: '',
        period_end: ''
      }
    }

    triggersState.triggers_stat = metrics.triggers_stat
    triggersState.period_start = metrics.period_start
    triggersState.period_end = metrics.period_end
  }

  function triggersTimeTicks() {
    if (triggersState) {
      return fromToTicks(triggersState.period_start, triggersState.period_end, { format: "YYYY-MM-DD 00:00:00" })
    } else {
      return fromToTicks(
          moment().add(-1, 'month').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"),
          { format: "YYYY-MM-DD 00:00:00" }
          )
    }
  }

  function triggersFiringTimeTicks() {
    if (triggersState) {
      return fromToTicks(triggersState.period_start, triggersState.period_end)
    } else {
      return fromToTicks(moment().add(-1, 'month').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
    }
  }

  function getTriggersFiringSum() {
    if (triggersState) {
      return sumBy(triggersState.triggers_stat, (record) => record.count)
    }

    return 0
  }

  function allTriggers() {
    return (store.state.triggersState.triggers?.active ?? []).concat(store.state.triggersState.triggers?.disabled ?? [])
  }

  function topTrigger() {
    if (triggersState) {
      const sortedRecords = triggersState.triggers_stat.slice().sort((a, b) => b.count - a.count)
      const triggers = allTriggers()

      if (sortedRecords.length && triggers) {
        return triggers.find((item) => item.id === sortedRecords[0].trigger)?.name ?? '-'
      }

      return '-'
    }

    return '-'
  }

  function groupTriggers() {
    if (triggersState) {
      const res = groupBy(triggersState.triggers_stat, (i) => i.trigger)

      const triggers = allTriggers()

      if (triggers.length) {
        return Object
            .entries(res)
            .map(([ key, info ]) => {
              const seriesData = getChart(info)
              const trigger = triggers.find((t) => t.id === key)

              return {
                key,
                total: sum(seriesData),
                name: trigger?.name ?? i18n.t('trigger_option_not_found').toString(),
                series: [ {
                  name: i18n.t('trigger_fired_times').toString(),
                  data: seriesData
                } ]
              }
            })
            .sort(((a, b) => b.total - a.total))

      } else {
        return []
      }
    } else {
      return []
    }
  }

  function getChart(data:Array<TriggerStatRecord>) {
    const { mapTimeData } = triggersTimeTicks()

    data.forEach(value => {
      mapTimeData[value.time] += value.count
    })

    return Object.values(mapTimeData)
  }

  function triggersFiringSeries() {
    const { mapTimeData } = triggersFiringTimeTicks()

    if (triggersState) {
      triggersState.triggers_stat.forEach(record => {
      const formattedTime = moment(record.time).format('YYYY-MM-DD')

      mapTimeData[formattedTime] += record.count
    })
    } else {
      console.error(`triggersState is null`)
    }

    return [ {
      data: Object.values(mapTimeData),
      name: i18n.t('dashboard_statistics_triggers_executions')
    } ]
  }

  return {
    triggersTimeTicks,
    triggersFiringTimeTicks,
    getTriggersFiringSum,
    topTrigger,
    groupTriggers,
    triggersFiringSeries,
  }
}
