








import TriggersList from '@/components/TriggerSetup/TriggersList.vue'

import CenteredColumnLayout from 'piramis-base-components/src/components/CenteredColumnLayout.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  'components': {
    TriggersList,
    PageTitle,
    CenteredColumnLayout
  },
})
export default class Triggers extends Vue {
  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
