


































































































































































import TriggersListSectionTitle from '@/components/TriggerSetup/TriggersListSectionTitle.vue'
import checkChat from '@/assets/utils/checkChat'
import VuTriggers from '@/includes/logic/triggers/VuTriggers'
import TriggerCardListWrapper from '@/components/TriggerSetup/TriggerCardListWrapper.vue'
import { GetChatTriggersRes } from '@/includes/types/triggers'
import TriggersMixin from '@/mixins/TriggersMixin'
import { RawTriggerData, TriggersStatistics } from "@/components/TriggerSetup/logic/types/types";
import TriggersListMixin from "@/mixins/TriggersListMixin";
import NetworkChatEntities, { NetworkChatEntitiesData } from "@/components/networks/NetworkChatEntities.vue";
import ImportConfig from "@/components/chat/ChatExtra/ImportConfig/ImportConfigs.vue";
import { ImportTypeEnum } from "@/components/chat/ChatExtra/ImportConfig/types";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";
import MetricTriggers from "@/components/CommunityDashboard/MetricComponents/MetricTriggers.vue";
import RestoreTriggerModal from "@/components/TriggerSetup/RestoreTriggerModal.vue";
import { ITriggersState } from "@/store/triggers/state";
import TriggersModeSwitch from "@/components/TriggerSetup/TriggersModeSwitch.vue";

import SearchTextInput from 'piramis-base-components/src/components/Pi/fields/SearchTextInput/SearchTextInput.vue'
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";

import { Component, Mixins, Watch } from 'vue-property-decorator'
import arrayMove from 'array-move'

@Component({
  data() {
    return {
      ImportTypeEnum,
      SitePermissionEnum
    }
  },
  'components': {
    TriggersModeSwitch,
    RestoreTriggerModal,
    MetricTriggers,
    PermissionsBanner,
    ImportConfig,
    NetworkChatEntities,
    TriggersListSectionTitle,
    TriggerCardListWrapper,
    SearchTextInput,
  },
})
export default class TriggersList extends Mixins(ModelSetter, TriggersMixin, TriggersListMixin) {
  isImportModalOpen = false

  isRestoreModalOpen = false

  triggersStat: null | TriggersStatistics = null

  isTriggersStatisticsModalOpen = false

  triggersLoading = false

  @Watch('$route.params.CHAT_ID')
  onChatIdChange() {
    this.updateTriggers()
  }

  get canChangeConfig() {
    return this.$store.getters.canChangeConfig
  }

  get frontendTriggers(): ITriggersState['triggers'] {
    return this.$store.state.triggersState.triggers
  }

  get mainSectionTitle() {
    if (this.searchQuery.trim() || !this.inactiveTriggers.length && !this.activeTriggers.length) {
      return this.$t('trigger_trigger_list')
    } else {
      return this.$t('trigger_active_trigger_list')
    }
  }

  get mainSectionTriggers() {
    if (!this.searchQuery.trim()) {
      return this.activeTriggers
    } else {
      return this.queriedTriggers(this.activeTriggers.concat(this.inactiveTriggers))
    }
  }

  get activeTriggers(): Array<RawTriggerData> {
    return this.frontendTriggers?.active ?? []
  }

  get inactiveTriggers(): Array<RawTriggerData> {
    return this.frontendTriggers?.disabled ?? []
  }

  get networkTriggers(): NetworkChatEntitiesData {
    return (this.frontendTriggers?.networks ?? [])
      .map(n => {
        const { triggers, ...network } = n

        return {
          network,
          data: triggers
        }
      })
  }

  get hasTriggerLimits(): string {
    let message = ''

    const chat = this.$store.state.chatState.chat
    const limits = chat.limits
    const max_triggers = limits.max_triggers
    const triggersLength = chat.triggers.length

    if (triggersLength > max_triggers) {
      message += `<span>${ this.$t('max_triggers') }: <b>${ triggersLength }</b> ${ this.$t('of') } <b>${ max_triggers }</b></span>`
    }

    return message
  }

  highlightRestored({ triggers, id }: { triggers: GetChatTriggersRes<true>, id: RawTriggerData['id'] }) {
    this.$store.commit('UPDATE_TRIGGERS', triggers);
    this.$store.commit("SET_UPDATED_TRIGGER_ID", id);
  }

  selectDropdownItem(key: 'import' | 'restore' | 'disableAll' | 'deleteAll') {
    if (key === "import") {
      this.isImportModalOpen = true
    } else if (key === "restore"){
      this.isRestoreModalOpen = true
    } else if (key === "disableAll") {
      this.$confirm({
        title: this.$t('triggers_disable_all_confirm_title'),
        content: this.$t('triggers_disable_all_confirm_content'),
        onOk: () => {
          this.triggersLoading = true

          VuTriggers.setTriggers([])
            .then(() => this.triggersLoading = false)
        }
      })
    } else if (key === "deleteAll") {
      this.$confirm({
        title: this.$t('triggers_delete_all_confirm_title'),
        content: this.$t('triggers_delete_all_confirm_content'),
        onOk: () => {
          this.triggersLoading = true

          VuTriggers.deleteTrigger([])
            .then(() => this.triggersLoading = false)
        }
      })
    }
  }

  moveToDirection(data: { id: string, direction: 'left' | 'right' }): void {
    const { id, direction } = data
    const index = this.getActiveTriggersIds.indexOf(id)
    const movedTriggers = arrayMove(this.getActiveTriggersIds, index, direction === 'left' ? index - 1 : index + 1) as Array<string>

    VuTriggers.setTriggers(movedTriggers)
  }

  moveToIndex(data: { id: string, newIndex: number, callback?: () => void }): void {
    const { id, newIndex } = data
    const index = this.getActiveTriggersIds.indexOf(id)
    const movedTriggers = arrayMove(this.getActiveTriggersIds, index, newIndex) as Array<string>

    VuTriggers.setTriggers(movedTriggers)
      .then((res) => {
        if (res && typeof data.callback === 'function') {
          data.callback()
        }
      })
  }

  isTriggerActive(id: RawTriggerData['id']) {
    return this.frontendTriggers?.active?.some(t => t.id === id)
  }

  hasLimits(trigger: RawTriggerData): string {
    let message = ''

    if (this.$store.getters.isChatSet) {
      const limits = this.$store.state.chatState.chat.limits
      const max_trigger_actions = limits.max_trigger_actions
      const max_trigger_conditions = limits.max_trigger_conditions

      const count = (data: any) => {
        let i = 0

        if (data && data.length) {
          data.forEach(function (val: any) {
            if (val.actions) {
              val.actions.forEach(() => i++)
            } else {
              val.forEach(() => i++)
            }

          })
        }

        return i
      }

      const actionsCount = count(trigger.actions)
      if (actionsCount > max_trigger_actions) {
        message += `<p>${ this.$t('max_trigger_actions') }: <b>${ actionsCount }</b> ${ this.$t('of') } <b>${ max_trigger_actions }</b></p>`
      }

      const conditionsCount = count(trigger.conditions)
      if (conditionsCount > max_trigger_conditions) {
        message += `<p>${ this.$t('max_trigger_conditions') }: <b>${ conditionsCount }</b> ${ this.$t('of') } <b>${ max_trigger_conditions }</b></p>`
      }

      return message
    }

    return message
  }

  removeTrigger(id: string): void {
    VuTriggers.deleteFrontendTrigger(id)
  }

  gotoTrigger(actionType: 'new' | 'edit', triggerId?: string): void {
    const params: any = {}

    if (triggerId) {
      params['triggerId'] = triggerId
    }

    if (checkChat(this.$route.path)) {
      this.$router.push({
        name: 'chat_trigger',
        params: {
          actionType,
          ...params
        },
      })
    } else {
      this.$router.push({
        name: 'global_trigger',
        params: {
          actionType,
          ...params
        },
      })
    }
  }

  editTrigger(id: string): void {
    this.gotoTrigger('edit', id)
  }

  copyTrigger(id: string): void {
    VuTriggers.copyTrigger(id)

    this.gotoTrigger('new')
  }

  addToChat(id: string): void {
    this.triggersLoading = true
    VuTriggers.activateTrigger(id)
      .then(() => this.triggersLoading = false)
  }

  removeFromChat(id: string): void {
    this.triggersLoading = true
    VuTriggers.disableTrigger(id)
      .then(() => this.triggersLoading = false)
  }

  updateTriggers(): void {
    this.triggersLoading = true

    this.$store.dispatch('updateTriggers')
      .then(() => this.triggersLoading = false)
  }

  statisticsClick() {
    this.isTriggersStatisticsModalOpen = true

    VuTriggers.getChatTriggersStat()
      .then(res => {
        if (res) {
          this.triggersStat = res
        } else {
          this.isTriggersStatisticsModalOpen = false
        }
      })
  }

  mounted(): void {
    this.updateTriggers()
  }
}
