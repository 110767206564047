





















































import VuTriggers from "@/includes/logic/triggers/VuTriggers";
import TriggerSetup from "@/components/TriggerSetup/TriggerSetup.vue";
import { TriggerFactory } from "@/components/TriggerSetup/logic/trigger/trigger-factory";
import { RawTriggerData, TriggerData } from './logic/types/types';
import { GetChatTriggersRes } from "@/includes/types/triggers";
import { successNotification } from "@/includes/NotificationService";

import { SelectOption } from "piramis-base-components/src/logic/types";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, VModel, Watch, Mixins, Emit } from 'vue-property-decorator';
import SWorker from 'simple-web-worker'

@Component({
  components: {
    TriggerSetup,
    SelectInput
  }
})
export default class RestoreTriggerModal extends Mixins(ModelSetter) {
  @VModel() isModalOpen!: boolean

  @Watch('isModalOpen', { immediate: true })
  onChange(state: boolean) {
    if (state) {
      this.getInactiveTriggers()
    }
  }

  @Emit()
  restored(id: RawTriggerData['id'], triggers: GetChatTriggersRes<true>) {
    return {
      id,
      triggers
    }
  }

  isTriggerLoading = false

  triggerFactory: TriggerFactory = new TriggerFactory()

  triggersToRestore: Array<RawTriggerData> | null = null

  triggerId: string | null = null

  selectedTriggerModel: TriggerData | null = null

  get triggerOptions(): Array<SelectOption> {
    if (this.triggersToRestore) {
      return this.triggersToRestore.map(t => ({
        label: t.name,
        value: t.id
      }))
    } else {
      return []
    }
  }

  inputTrigger(o: SelectOption) {
    if (o) {
      this.isTriggerLoading = true

      SWorker.run((list: Array<RawTriggerData>, id: string) => {
        return list.find(t => t.id === id)
      }, [ this.triggersToRestore ?? [], o.value ])
        .then((res: RawTriggerData | undefined) => {
          if (res) {
            try {
              this.selectedTriggerModel = this.triggerFactory.createNewTriggerFromExists(res)
            } finally {
              this.isTriggerLoading = false
            }
          }
        })
    }
  }

  restoreTrigger() {
    if (this.triggerId) {
      this.isTriggerLoading = true

      VuTriggers.restoreTrigger([ this.triggerId ])
        .then(res => {
          if (res) {
            this.restored(this.triggerId!, res)
          }

          this.isTriggerLoading = false
          this.isModalOpen = false
        })
    }
  }

  getInactiveTriggers() {
    VuTriggers.getTriggers<false>(false)
      .then(res => {
        if (res) {
          this.triggersToRestore = res.triggers
        }
      })
  }

  resetState() {
    this.selectedTriggerModel = null
    this.triggerId = null
  }
}
