var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"modal-overflow-visible",attrs:{"width":!_vm.$screen.md ? '100%' : '85%',"footer":null,"destroy-on-close":"","title":_vm.$t('triggers_restore_title'),"after-close":_vm.resetState},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[_c('select-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'triggerId',
        'prefix': 'triggers_restore_',
        'options': _vm.triggerOptions,
        'multiple': false,
        'clearable': false,
        'supportButtons': false
      }
    }},on:{"input":_vm.inputTrigger}},[_c('a-button',{staticClass:"btn-success btn-wrap",attrs:{"slot":"field-button","icon":"undo","disabled":!_vm.triggerId || _vm.isTriggerLoading},on:{"click":_vm.restoreTrigger},slot:"field-button"},[_vm._v(" "+_vm._s(_vm.$t('triggers_restore_restore_button_title'))+" ")])],1),_c('a-spin',{staticClass:"w-full mt-5",attrs:{"spinning":_vm.isTriggerLoading}},[(_vm.selectedTriggerModel)?[_c('h3',[_vm._v(_vm._s(_vm.$t('triggers_restore_trigger_preview')))]),_c('trigger-setup',{attrs:{"disabled":true,"controls":{}},model:{value:(_vm.selectedTriggerModel),callback:function ($$v) {_vm.selectedTriggerModel=$$v},expression:"selectedTriggerModel"}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }